<template>
    <b-modal v-model="show" size="lg" hide-footer header-class="card-header pause v2ModalHeader" :title="'Customer information'" @hide="closeModal">
        <div class="row" style="cursor: default;">
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Initials:</p>
                <p class="v2ModalText">{{ user.initials }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Name:</p>
                <p class="v2ModalText">{{ user.name }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Last name prefix:</p>
                <p class="v2ModalText">{{ user.lastNamePrefix }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> LastName:</p>
                <p class="v2ModalText">{{ user.lastName }}</p>
            </div>
        </div>
        <div class="row" style="cursor: default;">
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Country:</p>
                <p class="v2ModalText">{{ user.country }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> City:</p>
                <p class="v2ModalText">{{ user.city }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Street:</p>
                <p class="v2ModalText">{{ user.street }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> House number:</p>
                <p class="v2ModalText">{{ user.houseNumber }}</p>
            </div>
        </div>
        <div class="row" style="cursor: default;">
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Postal code:</p>
                <p class="v2ModalText">{{ user.postalCode }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Phone number:</p>
                <p class="v2ModalText">{{ user.phoneNumber }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Email:</p>
                <p class="v2ModalText">{{ user.email }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Date of birth:</p>
                <p class="v2ModalText">{{ user.dateOfBirth }}</p>
            </div>
        </div>
        <div class="row" style="cursor: default;">
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Id:</p>
                <p class="v2ModalText">{{ user.id }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Provider name:</p>
                <p class="v2ModalText">{{ user.providerName }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Is adult:</p>
                <p class="v2ModalText">{{ user.isAdult }}</p>
            </div>
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Is fully verified:</p>
                <p class="v2ModalText">{{ user.isFullyVerified }}</p>
            </div>
        </div>
        <div class="row" style="cursor: default;">
            <div class="col-3" style="display: flex;">
                <p class="v2ModalDescr"> Validated at:</p>
                <p class="v2ModalText">{{ formatDate(user.createdAt) }}</p>
            </div>
        </div>

    </b-modal>
</template>

<script>
import moment from 'moment'

export default {
    name: 'IdinModal',
    props: ['activeLanguage', 'show', 'user'],
    data(){
        return {

        }
    },
    mounted() {
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        formatDate(createdAt) {
            return createdAt && createdAt.date ? moment(createdAt.date).format('DD-MM-YYYY') : '-'
        }
    }
}
</script>

<style>
    .modal-content {
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    .v2ModalHeader {
        border-bottom: 1px solid lightgrey;
        background-color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .modal-header.card-header.pause.v2ModalHeader .modal-title, .modal-header.card-header.pause .close {
        color: grey !important;
        cursor: default;
    }
    .v2ModalDescr {
        font-weight: bold !important;
        font-size: small !important;
    }
    .v2ModalText {
        margin-left: 10px;
        font-size: smaller;
    }
</style>