<template>
    <div class="main">
        <div class="container">
            <div class="trxTitle">
                <h1>{{ activeLanguage.title }}</h1>
            </div>
            <div class="trxMainTables">
                <div class="col-12">

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-bottom: 0;">Filters</p>
                        </div>
                        <div class="trxTableMain">
                            <div style="display: flex">
                                <div class="col-3">
                                    <label for="postalCode" class='trxInputLabel'>{{ activeLanguage.postal_code }}</label>
                                    <input type="text" v-model="form.postalCode" class="form-control" id="postalCode">
                                </div>
                                <div class="col-3">
                                    <label for="houseNumber" class='trxInputLabel'>{{ activeLanguage.house_number }}</label>
                                    <input type="text" v-model="form.houseNumber" class="form-control" id="houseNumber">
                                </div>
                                <div class="col-3">
                                    <label for="email" class='trxInputLabel'>{{ activeLanguage.email }}</label>
                                    <input type="text" v-model="form.email" class="form-control" id="email">
                                </div>
                                <div class="col-3">
                                    <label for="phoneNumber" class='trxInputLabel'>{{ activeLanguage.phoneNumber }}</label>
                                    <input type="text" v-model="form.phoneNumber" class="form-control" id="phoneNumber">
                                </div>
                            </div>
                            <div class="btns" style="margin-left: auto;">
                                <button class="btn btn-danger productListTitleBtn filterBtn trxDeleteBtn" style="width: auto; margin-right: 10px;" @click="clearFilter">{{ activeLanguage.clearBtn }}</button>
                                <button type="submit" class="btn productListTitleBtn filterBtn" @click="getIdinList" >{{ activeLanguage.search }}</button>
                            </div>
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="row trxTableHeader" style="margin-top: 20px;">
                                <div class="trxProductListTableTh" style="width: 15%; padding-left: 10px;">{{ activeLanguage.name }}</div>
                                <div class="trxProductListTableTh" style="width: 15%;">{{ activeLanguage.city }}</div>
                                <div class="trxProductListTableTh" style="width: 15%;">{{ activeLanguage.address }}</div>
                                <div class="trxProductListTableTh" style="width: 10%;">{{ activeLanguage.postal_code }}</div>
                                <div class="trxProductListTableTh" style="width: 10%;">{{ activeLanguage.birthdate }}</div>
                                <div class="trxProductListTableTh" style="width: 10%;">{{ activeLanguage.phoneNumber }}</div>
                                <div class="trxProductListTableTh" style="width: 15%;">{{ activeLanguage.email }}</div>
                                <div class="trxProductListTableTh" style="width: 10%; margin-left: -15px;">{{ activeLanguage.validatedAt }}</div>
                            </div>
                        <div class="trxTableMain">
                            <div class="trxProduct trxProductListTableTbodyTr customerRow" 
                                v-for="(customer, index) in idinList" 
                                @click="openModal($event, customer, index)"
                                :class="{ 'selected' : selectedIndex === index}">
                                <div class="" style="width: 15%;">
                                    <p class="trxProductListTableP shortText" >{{ customer.name }} {{ customer.lastName  }}</p>
                                </div>
                                <div class="" style="width: 15%;">
                                    <p class="trxProductListTableP shortText" >{{ customer.city }}</p>
                                </div>
                                <div class="" style="width: 15%;">
                                    <p class="trxProductListTableP shortText" >{{ customer.street }} {{ customer.houseNumber}}</p>
                                </div>
                                
                                <div class="" style="width: 10%;">
                                    <p class="trxProductListTableP shortText" >{{ customer.postalCode }}</p>
                                </div>
                                
                                <div class="" style="width: 10%;">
                                    <p class="trxProductListTableP shortText" >{{ customer.dateOfBirth }}</p>
                                </div>
                                <div class="" style="width: 10%;">
                                    <p class="trxProductListTableP shortText" >{{ customer.phoneNumber }}</p>
                                </div>
                                <div class="" style="width: 15%;">
                                    <p class="trxProductListTableP shortText" style="text-align: left;" >{{ customer.email }}</p>
                                </div>
                                <div class="" style="width: 10%;">
                                    <p class="trxProductListTableP shortText" style="text-align: left;" >{{ formatDate(customer.createdAt) }}</p>
                                </div>
                            </div>

                        </div>
                        <div class="row" v-if="pagination.productsPerPage < pagination.maxItems">
                                <div class="col-4"></div>
                                <div class="col-4 trxPagination">
                                    <p class="trxProductListTableP">{{ activeLanguage.rows_per_page }}</p>
                                    <select name="productsPerPage" id="productsPerPage" v-model="pagination.productsPerPage" class="form-control productsPerPage">
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <div class="col-3 paginationInfo">
                                    <div class="pagCount col-8">
                                        <p class="trxProductListTableP">{{ pageCount }}</p>
                                    </div>
                                    <div class="paginationArrows col-3">
                                        <i class="fa fa-angle-left" aria-hidden="true" @click="getPrevPage"></i>
                                        <i class="fa fa-angle-right" aria-hidden="true" @click="getNextPage"></i>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <IdinModal :activeLanguage="activeLanguage" :show="showModal" :user="selectedCustomer" @close="closeModal"/>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import IdinModal from '../idin/IdinModal.vue'
import moment from 'moment'

export default {
    name: 'idin',
    data() {
        return {
            idinList: [],
            form: {},
            pagination: {
                currentPage: 1,
                maxItems: null,
                total_pages: null,
                productsPerPage: 20
            },
            showModal: false,
            selectedCustomer: {},
            selectedIndex: null
        }
    },
    mounted() {
        this.getIdinList()
    },
    methods: {
        getIdinList(page = 1, perPage = 20){
            let params = {
                postalCode: this.form.postalCode,
                houseNumber: this.form.houseNumber,
                email: this.form.email,
                phoneNumber: this.form.phoneNumber,
                page,
                perPage: this.pagination.productsPerPage
            }
            billinkAxios
                        .get('/app/id-verified-customers', {params})
                        .then(({ data }) => {
                            this.idinList = data.customers
                            this.pagination.currentPage = data.page
                            this.pagination.total_pages = data.total_pages
                            this.pagination.maxItems = data.total_customers
                        })
                        .catch(err => console.error(err))
        },
        getNextPage() {
            this.getIdinList(this.pagination.currentPage + 1 , this.pagination.productsPerPage)
        },
        getPrevPage(){
            this.getIdinList(this.pagination.currentPage - 1 , this.pagination.productsPerPage)
        },
        clearFilter() {
            this.form = {}
            this.getIdinList()
        },
        openModal(e, customer, index) {
            this.selectedCustomer = customer
            this.selectedIndex = index
            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        },
        formatDate(createdAt) {
            return createdAt && createdAt.date ? moment(createdAt.date).format('DD-MM-YYYY') : '-'
        }

    },
    computed: {
        ...mapGetters({
            activeLanguage: 'getIdinLanguage'
        }),
        pageCount() {
                let perPage = this.pagination.productsPerPage ? this.pagination.productsPerPage : 20 
				let orderCount = `${1 + this.pagination.currentPage * perPage - perPage} - ${this.pagination.currentPage * perPage}`,
                    count = `${orderCount} of ${this.pagination.maxItems} results`

                if (this.pagination.maxItems < 21) {
					count = `${this.pagination.maxItems} of ${this.pagination.maxItems} results`
                }

				return count
            }
    },
    components: {
        IdinModal
    }
}
</script>

<style>
    .filterBtn {
        width: 100px;
        margin-right: 15px;
        margin-left: auto;
        margin-top: 20px;
    }
    .shortText {
        max-width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .customerRow:hover {
        background: #ccf0ef !important;
    }
    .selected {
        background: #ccf0ef !important;
    }
</style>